import React, {useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';

export interface NewsletterActivationResultProps {
  isActivationSuccessful: boolean,
  isMoto: boolean,
  isSweepstake: boolean,
  sweepstakeType: string | null;
  newsletterType: string | null;
}

export const NewsletterActivationResult = ({ isActivationSuccessful, isMoto, isSweepstake, sweepstakeType, newsletterType }: NewsletterActivationResultProps) => {
  const { t } = useTranslation();
  const status = isActivationSuccessful ? 'success' : 'failure';
  const url: string = isMoto ? 'https://motorrad.suzuki.de/newsletter' : 'https://auto.suzuki.de/ueber-suzuki/newsletter';
  const baseBannerSourceUrl = 'https://signatur.suzuki.de/banners/redirection-pages';
  const bannerPath = `${baseBannerSourceUrl}/${isMoto ? 'moto' : 'auto'}/${isSweepstake ? 'sweepstakes' : 'newsletters'}`;
  const defaultBanner = `${bannerPath}/default.jpg`;

  const [bannerSource, setBannerSource] = useState<string>(() => {
    if (isSweepstake) {
      return sweepstakeType ? `${bannerPath}/${sweepstakeType}.jpg` : defaultBanner;
    }
    return newsletterType !== '' ? `${bannerPath}/${newsletterType}.jpg` : defaultBanner;
  });

  const getHeaderElement = () => {
    if (!isActivationSuccessful) {
      return <h1 className="md:text-5xl text-3xl font-suzuki-headline mb-4">{t('activate_newsletter_subscription.failure.headline')}</h1>;
    }
    return (
      <h1 className="md:text-5xl text-3xl font-suzuki-headline mb-4">
        {isSweepstake ? t('activate_newsletter_subscription.sweepstake.header') : t('activate_newsletter_subscription.success.headline')}
      </h1>
    );
  };

  const getBodyElement = () => {
    if (isActivationSuccessful) {
      return (
        <Trans i18nKey={isSweepstake ? 'activate_newsletter_subscription.sweepstake.body' : `activate_newsletter_subscription.success.body.${newsletterType ? newsletterType : 'default_body'}`}>
          newsletter<a className="underline" href={url} >newsletter</a>
        </Trans>
      );
    }
    return (
      <Trans i18nKey={`activate_newsletter_subscription.failure.body_${isMoto ? 'moto' : 'auto'}`}>
        newsletter<a className="underline" href={url} >newsletter</a>
      </Trans>
    );
  };

  return (
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col items-center justify-center gap-y-4">
            {getHeaderElement()}
            <p className="text-lg font-suzuki-regular mb-6 text-justify">
              {getBodyElement()}
            </p>
            {
              isMoto ?
                <div className="w-full md:w-2/3 lg:w-1/2">
                  <img
                    src={bannerSource}
                    alt="Newsletter subscription banner"
                    className="w- h-auto object-cover"
                    onError={() => setBannerSource(defaultBanner)}
                  />
                </div>
                :
                <img
                  src={bannerSource}
                  alt="Newsletter subscription banner"
                  className="w-full h-auto object-cover"
                  onError={() => setBannerSource(defaultBanner)}
                />
            }
            <p className="text-lg mt-6">{t(`activate_newsletter_subscription.${status}.ending`)}</p>
          </div>
        </div>
  );
};
