import React, { useEffect, useState } from 'react';
import { Spinner } from '@sde/basic-ui-library';
import { useParams, useSearchParams } from 'react-router-dom';
import { NewsletterUnsubscriptionResult } from "../../components/newsletter/newsletter-unsubscription-result";
import {getNewsletterType, unsubscribe} from "../../store/slices/newsletter.slice";

let hasRequested = false;
let hasTypeRequested = false;

export default function UnsubscribeNewsletter() {
  const { tenant } = useParams();
  const isMoto = tenant === 'moto';
  const [searchParams] = useSearchParams();
  const [isNewsletterBeingUnsubscribed, setIsNewsletterBeingUnsubscribed] = useState<boolean>(true);
  const [isNewsletterUnsubscribed, setIsNewsletterUnsubscribed] = useState<boolean>(false);
  const [newsletterType, setNewsletterType] = useState<string>('');

  useEffect(() => {
    if (!hasTypeRequested) {
      getNewsletterType(searchParams.get('newsletterCode') ?? '').then(response => {
        setNewsletterType(response.newsletterType);
      }).catch(error => {
        console.error(error);
      });
      hasTypeRequested = true;
    }
  }, [searchParams]);

  useEffect(() => {
    if (!hasRequested) {
      unsubscribe({
        email: searchParams.get('email') || '',
        newsletterCode: searchParams.get('newsletterCode') ?? '',
      }).then(response => {
        setIsNewsletterUnsubscribed(response.code === '0');
        setIsNewsletterBeingUnsubscribed(false);
      }).catch(error => {
        console.error(error);
        setIsNewsletterUnsubscribed(false);
        setIsNewsletterBeingUnsubscribed(false);
      });
      hasRequested = true;
    }
  }, [searchParams]);

  return (
    <>
      {isNewsletterBeingUnsubscribed ?
        <div className='m-auto'>
          <Spinner />
        </div>
        :
        <NewsletterUnsubscriptionResult isUnsubscriptionSuccessful={isNewsletterUnsubscribed} isMoto={isMoto} newsletterType={newsletterType} />
      }
    </>
  );
}
