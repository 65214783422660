import { Sidebar, SidebarLink, SidebarLinkGroup } from '@suzuki-frontend-framework-update/ui';
import './communication.module.scss';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { isAdmin, isAutoDealerOrAdmin, isMotoDealer, isMotoDealerOrAdmin } from "../../helpers/session-utils";
import { useApiGetData } from "../../components/useApiGetData/useApiGetData";
import { environment } from "../../../environments/environment";
import { QuestionnairesType } from "../../models/questionnaires-types";
import { compareQuestionnaireTypes } from "../../helpers/questionnaire-helper";

export function Communication() {
  const {t} = useTranslation();
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const questionnairesTypes = useApiGetData<QuestionnairesType[]>(
    `${environment.restEndpoint}/survey-service/questionnaire-types`,
    'get',
    'questionnaireTypes'
  );

  function getSurveysItems() {
    const tenants: string[] = []
    if (isMotoDealerOrAdmin(session))
      tenants.push('moto')
    if (isAutoDealerOrAdmin(session))
      tenants.push('auto')

    const items = questionnairesTypes.data?.sort((a, b) => compareQuestionnaireTypes(a, b)).filter((type) => tenants.includes(type.tenant)).map((type) =>
      ({
        name: t('communication.surveys.' + type.name + (tenants.length === 2 ? '-prefix' : '')),
        path: 'surveys/' + type.id,
        subPaths: [
          'surveys/' + type.id + '/toSend',
          'surveys/' + type.id + '/sent',
        ],
      } as SidebarLink))
    return items ?? [];
  }

  function getTestDriveItems(): SidebarLink[] {
    const report: SidebarLink = {
      name: t('communication.test-drive.report.title'),
      path: 'test-drives-report'
    }
    let importItem: SidebarLink|null = null

    if (isAdmin(session)) {
      report.subPaths = [`${report.path}/auto`, `${report.path}/moto`]
      importItem =  {
        name: t('communication.test-drive.import.title'),
        path: 'test-drives-import'
      }
    } else if (isMotoDealer(session)) {
      report.path = `${report.path}/moto`
      importItem =  {
        name: t('communication.test-drive.import.title'),
        path: 'test-drives-import'
      }
    } else {
      report.path = `${report.path}/auto`
    }

    return [report, importItem].filter(Boolean) as SidebarLink[];
  }

  const getSidebarGroups = () => {
    const groups: SidebarLinkGroup[] = [];
    let communication: SidebarLinkGroup = {
      title: t('communication.title'),
      items: [
        {
          name: t('communication.inspections-invitations.sideTitle'),
          path: 'inspections-invitations',
          subPaths: [
            'inspections-invitations/toSend',
            'inspections-invitations/sent',
          ],
          icon: {
            name: isAutoDealerOrAdmin(session) ? 'directions_car' : 'sports_motorsports',
            classname: 'border-2',
          },
        },
      ],
    }
    if (isMotoDealerOrAdmin(session)) {
      communication = {
        ...communication,
        items: [
          ...communication.items,
          {
            name: t(
              'communication.extended-warranty-reminder.sideTitle'
            ),
            path: 'extended-warranty-reminder',
            subPaths: [
              'extended-warranty-reminder/toSend',
              'extended-warranty-reminder/sent',
            ],
            icon: {name: 'star_outline'},
          },
        ]
      }
    }
    groups.push(communication)
    if (isAdmin(session)) {
      groups.push({
        title: t('communication.newsletter.title'),
        items: [
          {
            name: t('communication.newsletter.definition.side_title'),
            path: 'newsletter/overview',
          },
          {
            name: t('communication.newsletter.list.sideTitle'),
            path: 'newsletter/subscribers',
            subPaths: [
                'newsletter/subscribers/auto',
                'newsletter/subscribers/moto'
            ]
          },
          {
            name: t('communication.newsletter.export.sideTitle'),
            path: 'newsletter/export-history',
          },
          {
            name: t('communication.newsletter.verification.sideTitle'),
            path: 'newsletter/verification',
          },
        ],
      })
    }
    if (isAdmin(session)) {
      groups.push({
        title: t('communication.sweepstake.title'),
        items: [
          {
            name: t('communication.sweepstake.subpages.overview'),
            path: `sweepstakes`,
          },
        ],
      });
    }

    groups.push({
      title: t('communication.test-drive.title'),
      items: getTestDriveItems(),
    })
    if (isAutoDealerOrAdmin(session)) {
      groups.push({
        title: t('communication.web-request.title'),
        items: [
          {
            name: t('communication.web-request.subpages.offers'),
            path: 'web-request/offers',
          },
          {
            name: t('communication.web-request.subpages.business_offers'),
            path: '/communication/web-request/business-offers',
          },
          {
            name: t('communication.web-request.subpages.car-configuration-offers'),
            path: 'web-request/car-configuration-offers',
          },
          {
            name: t('communication.web-request.subpages.sweepstake-subscriptions'),
            path: 'web-request/sweepstakes-subscriptions',
          },
          {
            name: t('communication.web-request.subpages.consulting-appointments'),
            path: 'web-request/consulting-appointments',
          },
          {
            name: t('communication.web-request.subpages.service-appointments'),
            path: 'web-request/service-appointments',
          },
        ],
      })
    }
    const items = getSurveysItems();
    if (items.length > 0)
      groups.push({
        title: t('communication.surveys.title'),
        items: items,
      })
    return groups
  }

  return (
    <Sidebar groups={getSidebarGroups()}>
      <Outlet/>
    </Sidebar>
  );
}

export default Communication;
