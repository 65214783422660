import { WidgetValueRange } from '@suzuki-frontend-framework-update/ui';
import { useTranslation } from 'react-i18next';

export interface DashboardData {
  categories: DashboardDataCategories[];
}

export interface DashboardDataCategories {
  items: DashboardDataItem[];
}

export interface DashboardDataItem {
  key: number;
  value: number;
  hiddenFor?: DashboardUserRole[];
}

export enum DashboardUserRole {
  ADMIN = "ADMIN",
  ASM_DSM = "ASM_DSM",
  AUTO_DEALER = "AUTO_DEALER",
  MOTO_DEALER = "MOTO_DEALER"
}

export interface DashboardConfig {
  categories: DashboardCategory[];
}

export interface DashboardCategory {
  label: string;
  widgets: DashboardWidget[];
}

export interface DashboardWidget {
  id: string | number;
  label: string;
  type: 'kpi_widget' | 'gauge_widget' | 'chart_widget';
  valueRange: WidgetValueRange;
  value: number[];
  link?: string;
  onClick?: () => void;
}

export const DashboardConfig = (surveysWidgets: DashboardWidget[]): DashboardConfig => {
  const { t } = useTranslation();

  return {
    categories: [
      {
        label: t('crm.customer-retention-rate'),
        widgets: [
          {
            id: 1,
            label: t('crm.customer-retention-rate-desc-1-5'),
            type: 'gauge_widget',
            valueRange: {
              good: { begin: 50, end: 100 },
              warning: { begin: 30, end: 50 },
              info: null,
              bad: { begin: 0, end: 20 },
            },
            link: '/reports/crr?dashboard=true&range=5',
            value: [0, 20, 50, 100],
          },
          {
            id: 2,
            label: t('crm.customer-retention-rate-desc-1-10'),
            type: 'gauge_widget',
            valueRange: {
              good: { begin: 50, end: 100 },
              warning: { begin: 30, end: 50 },
              info: null,
              bad: { begin: 0, end: 20 },
            },

            link: '/reports/crr?dashboard=true&range=10',
            value: [0, 20, 50, 100],
          },
        ],
      },
      {
        label: t('crm.website-metrics.title'),
        widgets: [
          {
            id: 1,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.test-drive.new-test-drive-request'),
            valueRange: {
              good: { begin: 11, end: 50 },
              warning: null,
              info: null,
              bad: { begin: 0, end: 10, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/test-drives-report/auto?dashboardSevenDays=true',
          },
          {
            id: 2,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.test-drive.open-test-drive-request'),
            valueRange: {
              good: { begin: 11, end: 50 },
              warning: null,
              info: null,
              bad: { begin: 0, end: 10, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/test-drives-report/auto?dashboardOneMonth=true',
          },
          {
            id: 3,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.offers.private'),
            valueRange: {
              good: { begin: 0, end: 10 },
              warning: null,
              info: null,
              bad: { begin: 11, end: 50, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/web-request/offers?dashboard=true',
          },
          {
            id: 4,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.offers.business'),
            valueRange: {
              good: { begin: 0, end: 10 },
              warning: null,
              info: null,
              bad: { begin: 11, end: 50, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/web-request/business-offers?dashboard=true',
          },
          {
            id: 5,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.car-configuration-offers'),
            valueRange: {
              good: { begin: 0, end: 10 },
              warning: null,
              info: null,
              bad: { begin: 11, end: 50, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/web-request/car-configuration-offers?dashboard=true',
          },
          {
            id: 6,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.consulting-appointments'),
            valueRange: {
              good: { begin: 0, end: 10 },
              warning: null,
              info: null,
              bad: { begin: 11, end: 50, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/web-request/consulting-appointments?dashboard=true',
          },
          {
            id: 7,
            type: 'kpi_widget',
            label: t('crm.website-metrics.web-requests.service-appointments'),
            valueRange: {
              good: { begin: 0, end: 10 },
              warning: null,
              info: null,
              bad: { begin: 11, end: 50, color: '#c6ced1' },
            },
            value: [0.1, 0, 0, 0.49],
            link: '/communication/web-request/service-appointments?dashboard=true',
          },

        ],
      }, {
        label: t('crm.website-metrics.survey-tool.kpi'),
        widgets: surveysWidgets
      },
      /* Dashboard charts test area */
      {
        label: 'All Sales Enquiries by Month',
        widgets: [
          {
            id: 1,
            type: 'chart_widget',
            label: '',
            valueRange: {
              good: null,
              warning: null,
              info: null,
              bad: null,
            },
            value: [0.1, 0.2, 0, 0.5],
          }
        ]
      },
      {
        label: 'Combined sales forms | Last 60 days | All models',
        widgets: [
          {
            id: 1,
            type: 'chart_widget',
            label: '',
            valueRange: {
              good: null,
              warning: null,
              info: null,
              bad: null,
            },
            value: [0.1, 0.2, 0, 0.5],
          }
        ]
      },
    ],
  };
};
