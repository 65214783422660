import { BasicTable, Headline, Tab, TableFiltersProps, Tabs, } from '@sde/basic-ui-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApiGetData } from '../../components/useApiGetData/useApiGetData';
import { ProgressState, Receiver, SendoutStatus, SendoutStatusFilter, SurveysRequest, } from '../../models/receiver';
import { environment } from '../../../environments/environment';
import { DataFetchProps } from '../../models/others';
import { surveysFilterActions, surveysFilterSelector, } from '../../store/slices/surveys.slice';
import { AppDispatch } from '../../store/setup';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllUserManagement } from '../../store/slices/user-management.slice';
import { Session } from '@ory/client';
import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { Tooltip } from "@suzuki-frontend-framework-update/ui";
import * as Popper from "@popperjs/core";
import { format } from "react-string-format";
import ReportFilters from "../../components/report-filter/report-filter";
import { dashboardFilterSelector } from '../../store/slices/dashboard.slice';
import {webRequestFilterActions} from "../../store/slices/web-request-filter.slice";

export const Surveys = () => {
  const { t } = useTranslation();
  const { tabParam } = useParams();
  const { typeParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dashboard = new URLSearchParams(location.search).get('dashboard');
  const entities = useSelector(selectAllUserManagement);
  const [session, setSession] = useState({} as Session);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const surveysFilterState = useSelector(
    surveysFilterSelector
  );
  const dashboardFilterState = useSelector(
    dashboardFilterSelector
  );
  const dispatch: AppDispatch = useDispatch();

  const defaultSendOutStatus = [
    SendoutStatusFilter.SENDOUT_NOT_POSSIBLE,
    SendoutStatusFilter.POST,
    SendoutStatusFilter.EMAIL,
  ];

  useEffect(() => {
    if (
      surveysFilterState.key === '' ||
      location.key !== surveysFilterState.key
    ) {
      dispatch(surveysFilterActions.init(location.key));
    }

    dispatch(
      surveysFilterActions.updateSurveysFilter({
        ...surveysFilterState.surveysFilter.filter,
        sendOutStatus: surveysFilterState.surveysFilter.filter.sendOutStatus?.length
          ? surveysFilterState.surveysFilter.filter.sendOutStatus
          : defaultSendOutStatus,
      })
    );
  }, [dispatch]); 

  const [surveysRequest, setSurveysRequest] =
    useState<SurveysRequest>({
      pageSize: 100,
      pageIndex: 0,
      filter: {
        ...surveysFilterState.surveysFilter.filter,
        typeId: typeParam,
        progressStates: getProgressStates(),
        dealerCodes: []
      },
    });
  const receivers = useApiGetData<Receiver[]>(
    `${environment.restEndpoint}/survey-service/receiver/get-receivers`,
    'post',
    'receivers',
    surveysRequest
  );

  const receiversCount = useApiGetData<number>(
    `${environment.restEndpoint}/survey-service/receiver/get-receivers-count`,
    'post',
    'count',
    surveysRequest
  );

  const handleTabChange = (tabIndex: number) => {
    const sentTab = tabIndex === 1;

    const newFilters = {
      ...surveysFilterState.surveysFilter.filter,
      progressStates: sentTab
        ? [ProgressState.NOT_STARTED, ProgressState.IN_PROGRESS]
        : [ProgressState.NOT_SENT], 
      sendOutStatus: defaultSendOutStatus, 
      search: '', 
    };
    dispatch(surveysFilterActions.updateSurveysFilter(newFilters));

    navigate(
      `/communication/surveys/${typeParam}/${sentTab ? 'sent' : 'toSend'}`
    );
  };

  const getTabIndex = () => {
    switch (tabParam) {
      case 'toSend':
        return 0;
      case 'sent':
        return 1;
      default:
        return 0;
    }
  };

  const handleFilterSearch = (value: string) => {
    dispatch(
      surveysFilterActions.updateSurveysFilter({
        ...surveysFilterState.surveysFilter
          .filter,
        search: value,
      })
    );
  };

  const handleFilterSelect = (id: string, selected: string[]) => {
    dispatch(
      surveysFilterActions.updateSurveysFilter({
        ...surveysFilterState.surveysFilter.filter,
        progressStates: selected as ProgressState[],
      })
    );
  }

  const handleSendoutStatusFilterSelect = (id: string, selected: string[]) => {
    dispatch(
      surveysFilterActions.updateSurveysFilter({
        ...surveysFilterState.surveysFilter.filter,
        sendOutStatus: selected as SendoutStatusFilter[],
      })
    );
  }

  const filters = {
    id: 'receiversFilters',
    key: 'receiversFilters',
    placeholder: t(
      'communication.surveys.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
      surveysFilterState.surveysFilter.filter
        .search,
    searchDelay: 500,
    filters:[
        {
          id: 'sendoutDate',
          name: t(
            'communication.surveys.columns.sendoutMethod'
          ),
          items: [
            {
              id: SendoutStatusFilter.SENDOUT_NOT_POSSIBLE,
              name: t(
                'communication.surveys.columns.sendoutFailed'
              ),
            },
            {
              id: SendoutStatusFilter.POST,
              name: t(
                'communication.surveys.sendoutMethod.post'
              ),
            },
            {
              id: SendoutStatusFilter.EMAIL,
              name: t(
                'communication.surveys.sendoutMethod.email'
              ),
            },
          ],
          onChange: handleSendoutStatusFilterSelect,
          selected: surveysFilterState.surveysFilter.filter.sendOutStatus ?? defaultSendOutStatus,
        },
    ]
  } as TableFiltersProps;

  const filtersInvited = {
    id: 'invitedReceiversFilters',
    key: 'invitedReceiversFilters',
    placeholder: t(
      'communication.surveys.filters.search-placeholder'
    ),
    onSearchUpdate: handleFilterSearch,
    initialSearchValue:
      surveysFilterState.surveysFilter.filter
        .search,
    searchDelay: 500,
    filters: [
      {
        id: 'progressState',
        name: t(
          'communication.surveys.filters.progressState'
        ),
        items: [
          {
            id: ProgressState.NOT_STARTED,
            name: t(
              'communication.surveys.progressState.NOT_STARTED'
            ),
          },
          {
            id: ProgressState.IN_PROGRESS,
            name: t(
              'communication.surveys.progressState.IN_PROGRESS'
            ),
          },
          {
            id: ProgressState.COMPLETED,
            name: t(
              'communication.surveys.progressState.COMPLETED'
            ),
          },
        ],
        onChange: handleFilterSelect,
        selected: surveysFilterState.surveysFilter.filter.progressStates ?? getProgressStates(),
      },
    ]
  } as TableFiltersProps;

  const getErrorMessage = (sendoutErrors: string): React.ReactNode => {
    return (
      <span className="leading-normal">
        {format(
          t('communication.surveys.sendoutErrors.result'),
          sendoutErrors
            .split(',')
            .filter(n => n)
            .map((err) =>
              t('communication.surveys.sendoutErrors.' + err)
            )
            .join(', ')
        )}
      </span>
    );
  };

  const columnReceiverHelper = createColumnHelper<Receiver>();
  const columns: ColumnDef<Receiver>[] = [
    columnReceiverHelper.accessor((row) => row, {
      id: 'name',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.name')}</span>,
      cell: (info) => {
        const contact = info.getValue().contact
        return contact ? (
          <Link
            className="flex flex-col"
            to={
              contact.isCompany
                ? `/companies/view/${contact.objectId}`
                : `/contacts/view/${contact.objectId}`
            }
            onClick={(e) => {
              dispatch(
                surveysFilterActions.updateSurveysCurrentRow(
                  contact.id?.toString()
                )
              );
              e.stopPropagation();
            }}
          ><div className="flex flex-col underline" >
              <span>
                {contact.firstname} {contact.name}
              </span>
            </div>
          </Link>
        ) : <div />;
      },
    }),
    columnReceiverHelper.accessor((row) => row.contact, {
      id: 'address',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.address')}</span>,
      cell: (info) => {
        const contact = info.getValue()
        return <div className="flex flex-col">
          {contact?.street && (
            <span>{contact.street}</span>
          )}
          {contact?.city && (
            <span>
              {contact.zip ? contact.zip + ' ' : ''}
              {contact.city}
            </span>
          )}
        </div>
      },
    }),
    columnReceiverHelper.accessor((row) => row.contact.email, {
      id: 'email',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.email')}</span>,
      cell: (info) => {
        return <div className="flex flex-col">
          {info.getValue() && <span>{info.getValue()}</span>}
        </div>
      },
    }),
    columnReceiverHelper.accessor((row) => row.contact.phone, {
      id: 'phone',
      enableSorting: false,
      header: () => <span>{t('contacts.people-companies.phone')}</span>,
      cell: (info) => {
        return <div className="flex flex-col">
          {info.getValue() && <span>{info.getValue()}</span>}
        </div>
      },
    }),
    columnReceiverHelper.accessor((row) => row.vehicle, {
      id: 'vehicle',
      enableSorting: false,
      header: () => <span>{t('communication.surveys.columns.vehicle')}</span>,
      cell: (info) => {
        const vehicle = info.getValue()
        return vehicle ? (
          <Link
            className="flex flex-col"
            to={`/vehicles/view/${vehicle.objectId}`}
            onClick={(e) => {
              dispatch(
                surveysFilterActions.updateSurveysCurrentRow(
                  vehicle.id?.toString()
                )
              );
              e.stopPropagation();
            }}
          ><div className="flex flex-col underline" >
              <span>{(vehicle.brand + ' ' + vehicle.model).trim()}</span>
              <span className="text-blue-gray">{vehicle.vin}</span>
            </div>
          </Link>
        ) : <div />;
      },
    }),
    columnReceiverHelper.accessor((row) => row.sendoutDate, {
      id: 'sendoutDate',
      enableSorting: false,
      header: () => <span>{t('communication.surveys.columns.sendoutDate')}</span>,
      cell: (info) => {
        const sendoutDate = info.getValue()
        return (
          <span>
            {sendoutDate ? new Date(sendoutDate).toLocaleDateString() : '-'}
          </span>
        );
      },
    }),
    columnReceiverHelper.accessor((row) => row, {
      id: 'sendoutMethod',
      enableSorting: false,
      header: () => <span>{t('communication.surveys.columns.sendoutMethod')}</span>,
      cell: (info) => {
        const receiver = info.getValue();
        return receiver.status === SendoutStatus.INVALID ? (
          <span>
            {receiver.sendoutErrors ? (
              <Tooltip
                content={getErrorMessage(receiver.sendoutErrors)}
                placement={Popper.top}
              >
                <span>
                  {t('communication.surveys.columns.sendoutFailed')}
                </span>
              </Tooltip>
            ) : (
              <span>
                {t('communication.surveys.columns.sendoutFailed')}
              </span>
            )}
          </span>
        ) : (
          <span>
            {receiver.sendoutMethod ? (
              t('communication.surveys.sendoutMethod.' + receiver.sendoutMethod)
            ) : (
              '-'
            )}
          </span>
        );
      },
    }),
  ].filter(Boolean) as ColumnDef<Receiver>[]


  const progressColumn = columnReceiverHelper.accessor((row) => row.progressState, {
    id: 'progress',
    enableSorting: false,
    header: () => <span>{t('communication.surveys.columns.progress')}</span>,
    cell: (info) => {
      return <span>
        {t('communication.surveys.progressState.' + info.getValue())}
      </span>
    },
  }) as ColumnDef<Receiver>

  const handlePreviousRow = useCallback(
    async ({ itemsRef }: any) => {
      if (receivers.data && surveysFilterState.currentRow) {
        itemsRef.current
          .get(surveysFilterState.currentRow)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        dispatch(
          surveysFilterActions.updateSurveysCurrentRow(
            undefined
          )
        );
      }
    },
    [receivers.data, receivers.loading]
  );

  /*const handleRowClick = (row: Row<Receiver>) => {
    dispatch(surveysFilterActions.updateSurveysCurrentRow(row.id));
    const contact = row.original.contact
    navigate(contact.isCompany
      ? `/companies/view/${contact.objectId}`
      : `/contacts/view/${contact.objectId}`)
  };*/

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, pageSortBy }: DataFetchProps) => {
      dispatch(surveysFilterActions.updateSurveysPageIndex(pageIndex));
      setSurveysRequest({
        pageSize: pageSize,
        pageIndex: pageIndex,
        filter: {
          ...surveysFilterState.surveysFilter.filter,
          typeId: typeParam,
          progressStates: getProgressStates()
        },
      });
    },
    [surveysFilterState.surveysFilter.filter]
  );

  function getProgressStates() {
    if (tabParam === 'toSend') {
      // for toSend state needs to be NOT_SENT
      return [ProgressState.NOT_SENT]
    } else if (tabParam === 'sent') {
      if (surveysFilterState.surveysFilter.filter.progressStates === undefined) {
        // if state is not set first check if from dashboard
        if (dashboard === 'sent') return [ProgressState.NOT_STARTED, ProgressState.IN_PROGRESS, ProgressState.COMPLETED]
        if (dashboard === 'finished') return [ProgressState.COMPLETED]
        // if state is not set return default filters
        return [ProgressState.NOT_STARTED, ProgressState.IN_PROGRESS]
      }
      if (surveysFilterState.surveysFilter.filter.progressStates.length === 0) {
        // if no states are selected get all for sent
        return [ProgressState.NOT_STARTED, ProgressState.IN_PROGRESS, ProgressState.COMPLETED]
      }
    }
    return surveysFilterState.surveysFilter.filter.progressStates
  }

  useEffect(() => {
    dispatch(
      surveysFilterActions.updateSurveysFilter({
        ...surveysFilterState.surveysFilter.filter,
        dealerCodes: dashboardFilterState.dealerFilter.dealers,
      }),
    );
  }, [dashboardFilterState.dealerFilter.dealers, dispatch]);

  return (
    <div className="w-full h-full">
      <Headline
        className="p-8"
        title={t('communication.surveys.title')}
      />
      <div className="ml-8 mb-2 -mt-9">
        <ReportFilters />
      </div>
      <div className="mt-7">
        <Tabs defaultActiveTabIndex={getTabIndex()} onTabChange={handleTabChange}>
          <Tab
            title={t('communication.surveys.receivers')}
            tabIndex={0}
          >
            <BasicTable<Receiver>
              key="receivers-table"
              filter={filters}
              columns={columns}
              data={receivers.data ?? []}
              loading={receivers.loading}
              initPageSize={100}
              rowCount={receiversCount.data ?? 0}
              countLabel={
                receiversCount.data && receiversCount.data === 1
                  ? t('common.list.count.entry')
                  : t('common.list.count.entries')
              }
              fetchData={fetchData}
              disabledClickForColumns={['buttons']}
              initialPage={surveysFilterState.surveysFilter.pageIndex}
              resetOnChange={surveysFilterState.surveysFilter.filter}
              handleScrollToRow={handlePreviousRow}
              basic
            />
          </Tab>
          <Tab
            title={t('communication.surveys.invited')}
            tabIndex={1}
          >
            <BasicTable<Receiver>
              key="ivited-receivers-table"
              filter={filtersInvited}
              columns={[...columns, progressColumn]}
              data={receivers.data ?? []}
              loading={receivers.loading}
              initPageSize={100}
              rowCount={receiversCount.data ?? 0}
              countLabel={
                receiversCount.data && receiversCount.data === 1
                  ? t('common.list.count.entry')
                  : t('common.list.count.entries')
              }
              fetchData={fetchData}
              disabledClickForColumns={['buttons']}
              initialPage={surveysFilterState.surveysFilter.pageIndex}
              resetOnChange={surveysFilterState.surveysFilter.filter}
              handleScrollToRow={handlePreviousRow}
              basic
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Surveys;
