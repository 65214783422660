import { useTranslation } from 'react-i18next';
import { Button, ButtonStyle, Headline, RadioGroup, Spinner, Table } from '@suzuki-frontend-framework-update/ui';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useApiGetData } from "../../components/useApiGetData/useApiGetData";
import { environment } from "../../../environments/environment";
import { CrrEntry, GetCrrReportExportRequest, GetCrrReportRequest } from "../../models/crr-entry";
import { CrrReportData, CrrReportRow, getCellContent, getCurrentFiscalYear, getIdFromDate, monthNames, processEntries } from "./crr-report-parser";
import { Column } from "react-table";
import { format } from "react-string-format";
import ReportFilters from "../../components/report-filter/report-filter";
import { useDispatch, useSelector } from "react-redux";
import { dashboardFilterActions, dashboardFilterSelector } from "../../store/slices/dashboard.slice";
import { AppDispatch } from "../../store/setup";
import { useLocation, useNavigate } from "react-router-dom";
import { Dealer, DealersScope } from "../../models/dealer";
import { sendApiRequest } from "../../components/send-api-request/send-api-request";
import { base64StringToBlob } from "blob-util";
import download from "downloadjs";
import { toast } from "react-toastify";
import { Session } from "@ory/client";
import { selectAllUserManagement } from "../../store/slices/user-management.slice";
import { isAutoDealerOrAdmin } from "../../helpers/session-utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const CRRReport = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const entities = useSelector(selectAllUserManagement);
  const dashboard = new URLSearchParams(location.search).get('dashboard');
  const inspectionRange = new URLSearchParams(location.search).get('range');
  const [reportData, setReportData] = useState<CrrReportData>();
  const [selectedOption, setSelectedOption] = useState<string>('fiscal-year');
  const [visualization, setVisualization] = useState<string>(inspectionRange !== '10' ? 'fiscal-year-bonus' : 'fiscal-year');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [crrReportRequest, setCrrReportRequest] = useState<GetCrrReportRequest>({});
  const [session, setSession] = useState({} as Session);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const dispatch: AppDispatch = useDispatch();
  if (
    dashboardFilterState.key === '' ||
    location.key !== dashboardFilterState.key
  ) {
    dispatch(dashboardFilterActions.init(location.key));
  }

  const allDealers = useApiGetData<Dealer[]>(
    `${environment.restEndpoint}/dealer/dealer-service/dealers`,
    'post',
    'dealers'
  );

  const crrEntries = useApiGetData<CrrEntry[]>(
    `${environment.restEndpoint}/invitation/crr/report`,
    'post',
    'entries',
    crrReportRequest
  );

  const errorToast = (errorText: string) => {
    toast.error(errorText, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'excel-import-error',
    });
  };

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      if (!isAutoDealerOrAdmin(lastEntity.currentUser)){
        navigate(`/reports/survey/1`);
      }
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  useEffect(() => {
    if (crrEntries.data && startDate && endDate && startDate.getTime() < endDate.getTime()) {
      setReportData({
        rows: processEntries(crrEntries.data, startDate, endDate, visualization === 'fiscal-year-bonus' && selectedOption === 'fiscal-year'),
        columns: getColumns(startDate, endDate, visualization === 'fiscal-year-bonus' && selectedOption === 'fiscal-year')
      })
    }
  }, [crrEntries.data, visualization, selectedOption]);

  useEffect(() => {
    if (dashboardFilterState.dealerFilter.dealers && startDate && endDate && startDate.getTime() < endDate.getTime() && dashboardFilterState.dealerFilter.dealers.length > 0) {
      setCrrReportRequest({
        dealers: dashboardFilterState.dealerFilter.dealers,
        dateFrom: startDate,
        dateTo: endDate,
      });
    }
  }, [startDate, endDate, dashboardFilterState.dealerFilter.dealers]);

  function getColumns(startDate: Date, endDate: Date, isFiscalWithBonus: boolean) {
    const monthsColumns: Column<CrrReportRow>[] = []
    for (let date = new Date(startDate); date.getTime() < endDate.getTime(); date.setMonth(date.getMonth() + 1)) {
      const month = monthNames[date.getMonth()]
      const year = date.getFullYear()
      const dateString = `${date.getMonth()+1}-${date.getFullYear()}`
      monthsColumns.push({
        Header: month + ' ' + year,
        id: getIdFromDate(date),
        Cell: ({row, column}: any) => {
          const value = row.original.monthValues.get(column.id)
          return getCellContent(
            value,
            isFiscalWithBonus && row.original.inspectionNumber === -1,
            row.original.inspectionNumber === 0,
            dateString,
            row.original.inspectionNumber);
        },
      } as Column<CrrReportRow>)
    }

    return [
      {
        Header: '',
        id: 'inspectionNumber',
        Cell: ({row}: any) => {
          return (
            <div className={"flex flex-col items-center pr-4"}>
						<span className={"font-suzuki-bold"}>
              {t('reports.crr.inspection-number.title.' + row.original.inspectionNumber)}
						</span>
              <span>
                {row.original.inspectionNumber === -1 ?
                  format(t('reports.crr.inspection-number.description.' + row.original.inspectionNumber), isFiscalWithBonus ? '1. - 5.' : '1. - 10.') :
                  t('reports.crr.inspection-number.description.' + row.original.inspectionNumber)}
						</span>
            </div>
          );
        },
      },
      {
        Header: t('reports.crr.metrics.title'),
        id: 'metrics',
        Cell: ({row}: any) => {
          return row.original.inspectionNumber === 0 ? (
            <span className={"w-full text-left"}>
                {t(`reports.crr.metrics.zero`)}
						</span>
          ) : (
            <div className={"flex flex-col"}>
						<span className={"py-1 border-b border-gray w-full text-left"}>
             {format(t(`reports.crr.metrics.planned`), row.original.inspectionNumber === -1 ? isFiscalWithBonus ? '1. - 5' : '1. - 10' : row.original.inspectionNumber)}
						</span>
              <span className={"py-1 border-b border-gray w-full text-left"}>
                {format(t(`reports.crr.metrics.invited`), row.original.inspectionNumber === -1 ? isFiscalWithBonus ? '1. - 5' : '1. - 10' : row.original.inspectionNumber)}
						</span>
              <span className={"py-1 border-b border-gray w-full text-left"}>
                {format(t(`reports.crr.metrics.completed`), row.original.inspectionNumber === -1 ? isFiscalWithBonus ? '1. - 5' : '1. - 10' : row.original.inspectionNumber)}
						</span>
              <span className={"w-full text-left pt-1"}>
                {t(`reports.crr.metrics.rate`)}
						</span>
              {isFiscalWithBonus && row.original.inspectionNumber === -1 &&
                <span className={"pt-1 mt-1 border-t border-gray font-suzuki-bold w-full text-left"}>
                {t(`reports.crr.metrics.cumulated-rate`)}
						</span>
              }
            </div>
          );
        },
      },
      ...monthsColumns,
      {
        Header: t('reports.crr.sum'),
        id: 'sum',
        Cell: ({row}: any) => {
          const value = row.original.sum
          return getCellContent(value, isFiscalWithBonus && row.original.inspectionNumber === -1, row.original.inspectionNumber === 0);
        },
      },
      {
        Header: t('reports.crr.average'),
        id: 'average',
        Cell: ({row}: any) => {
          const value = row.original.average
          return getCellContent(value, isFiscalWithBonus && row.original.inspectionNumber === -1, row.original.inspectionNumber === 0);
        },
      }];
  }

  function getDealerData(dealers: Dealer[]) {
    let dealer: Dealer | undefined
    if (dashboardFilterState.dealerFilter.mainDealers.length === 1) {
      dealer = dealers.find(d => d.dealercode === dashboardFilterState.dealerFilter.mainDealers[0])
    } else if (dashboardFilterState.dealerFilter.dealers.length === 1) {
      dealer = dealers.find(d => d.dealercode === dashboardFilterState.dealerFilter.dealers[0])
    }
    if (!dealer) {
      return <div/>
    }
    return <>
      <div className={"font-suzuki-bold"}>
        {dealer.name + ' (' + dealer.dealercode + ')'}
      </div>
      <div>
        {dealer.addressLine1}, {dealer.zipCode} {dealer.city}
      </div>
    </>;
  }

  const handleDownloadPdf = () => {
    const input = document.getElementById('crr-report-table');
    if (input) {
      html2canvas(input, {allowTaint:true, backgroundColor: "white", removeContainer: false, scale: 1.5 })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: input.offsetWidth > input.offsetHeight ? "l" : "p",
            unit: "px",
            format: [canvas.width, canvas.height]
          });
          pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
          pdf.save(`crr-report-${startDate?.getFullYear()}.pdf`);
        })
      ;
    }
  }

  const handleDownloadExcel = () => {
    setLoadingExport(true)
    sendApiRequest(
      `${environment.restEndpoint}/invitation/crr/report/export`,
      'POST',
      {
        dealers: dashboardFilterState.dealerFilter.dealers,
        mainDealers: dashboardFilterState.dealerFilter.mainDealers,
        asm: dashboardFilterState.dealerFilter.asm,
        dsm: dashboardFilterState.dealerFilter.dsm,
        qualityBonus: visualization === 'fiscal-year-bonus' && selectedOption === 'fiscal-year',
        dateFrom: startDate,
        dateTo: endDate,
      } as GetCrrReportExportRequest
    )
      .then((response) => {
        setLoadingExport(false)
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        const file = base64StringToBlob(
          data.content,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        download(
          file,
          `crr-report-${startDate?.getFullYear()}.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      })
      .catch((e) => {
        errorToast(t('error') + e)
        setLoadingExport(false)
      });
  }

  const handleDownloadSummary = () => {
    setLoadingSummary(true)
    sendApiRequest(
      `${environment.restEndpoint}/invitation/crr/report/summary${startDate ? '?fiscalYear=' + startDate.getFullYear() : ''}`,
      'GET'
    )
      .then((response) => {
        setLoadingSummary(false)
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(response.status + ' ' + response.statusText);
        }
      })
      .then((data) => {
        const file = base64StringToBlob(
          data.content,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        download(
          file,
          'customer_retention_report.xlsx',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      })
      .catch((e) => {
        errorToast(t('error') + e)
        setLoadingSummary(false)
      });
  };

  return (
    <div className="w-full h-full">
      <Headline className="p-8" title={t('reports.crr.title')}/>
      <div className="ml-8 mb-2 -mt-9">
        <ReportFilters
          dateFilters={{
            selectedDateOption: selectedOption,
            setSelectedDateOption: setSelectedOption,
            startDate: startDate,
            setStartDate: setStartDate,
            endDate: endDate,
            setEndDate: setEndDate,
            defaultDate: new Date(getCurrentFiscalYear(), 0, 1, 12)
          }}
          showOnlyDealersScope={DealersScope.AUTO}
        />
      </div>
      <div className={"flex flex-row justify-between px-8 -mt-2"}>
        <span className={"flex flex-row gap-x-4"}>
          {selectedOption === 'fiscal-year' && <RadioGroup
            size="6"
            values={[{value: "fiscal-year-bonus", label: t('reports.crr.filter.fiscal-year-bonus-inspection')},
              {value: "fiscal-year", label: t('reports.crr.filter.fiscal-year-inspection')}]}
            name={"visualization"}
            radioChange={setVisualization}
            value={visualization}/>}
        </span>
        <span className={"flex flex-row gap-x-4 truncate"}>
          <Button
            buttonStyle={ButtonStyle.DEFAULT}
            onClick={handleDownloadPdf}
          >
          {t('reports.crr.download.pdf')}
        </Button>
          {loadingExport ? <Spinner/> : <Button
            buttonStyle={ButtonStyle.DEFAULT}
            onClick={handleDownloadExcel}
          >
          {t('reports.crr.download.excel')}
        </Button>}
        {session.identity?.traits?.isAdmin && selectedOption === 'fiscal-year' ? (loadingSummary ? <Spinner/> : <Button
          buttonStyle={ButtonStyle.DEFAULT}
          onClick={handleDownloadSummary}
        >
          {t('reports.crr.download.summary')}
        </Button>) : <div/>
        }
        </span>
      </div>
      <div className={"p-8"}>
        <Table
          key="crr-report-table"
          id="crr-report-table"
          columns={reportData?.columns ? reportData?.columns : []}
          data={!crrEntries.loading && reportData?.rows ? reportData?.rows : []}
          loading={crrEntries.loading}
          basic
          resetOnChange={crrReportRequest}
          customElement={
            <div className={"bg-blue-gray p-2 border-b border-dark-blue-gray flex flex-row justify-between text-xs items-center"}>
              <div>
                <div>
                  {dashboardFilterState.dealerFilter.asm.length > 0 &&
                    <div><span
                      className={"font-suzuki-bold"}>ASM: </span>{dashboardFilterState.dealerFilter.asm.join(', ')}
                    </div>}
                  {dashboardFilterState.dealerFilter.dsm.length > 0 &&
                    <div><span
                      className={"font-suzuki-bold"}>DSM: </span>{dashboardFilterState.dealerFilter.dsm.join(', ')}
                    </div>}
                </div>
              </div>
              <div className={"flex flex-col items-center"}>
                {allDealers.data && (dashboardFilterState.dealerFilter.dealers.length === 1 || dashboardFilterState.dealerFilter.mainDealers.length === 1)
                  && <div className={"flex flex-col items-center"}>
                    {getDealerData(allDealers.data)}
                  </div>}
                {dashboardFilterState.dealerFilter.dealers.length > 1 &&
                  <div><span
                    className={"font-suzuki-bold"}>{t('reports.crr.dealer-count')}</span>{dashboardFilterState.dealerFilter.dealers.length}
                  </div>}
              </div>
              <div>
                <div>
                  <div><span
                    className={"font-suzuki-bold"}>{t('reports.crr.date')}</span>{new Date().toLocaleDateString()}</div>
                  {startDate && endDate &&
                    <div><span
                      className={"font-suzuki-bold"}>{t('reports.crr.period')}</span>{startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </div>}
                </div>
              </div>
            </div>
          }
          customComponentClassName={"flex flex-col"}
          customCellClassName="m-0 bg-white px-0 py-2 text-left text-xs h-16 truncate"
          customHeaderClassName="m-0 first:px-2 px-2 py-4 text-center text-dark bg-blue-gray font-suzuki-bold text-xs"
        />
      </div>
    </div>
  );
};

export default CRRReport;
