import './app.module.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Public } from './layouts/public';
import { Protected } from './layouts/protected';
import { Login } from './pages/login/login';
import Contacts from './pages/contacts/contacts';
import { PageNotFound } from './pages/page-not-found/page-not-found';
import { UsersConfig } from './pages/users-config/users-config';
import ContactDetails from './pages/contact-details/contact-details';
import { VehicleEdit } from './pages/vehicle-edit/vehicle-edit';
import VehicleDetails from './pages/vehicle-details/vehicle-details';
import { Vehicles } from './pages/vehicles/vehicles';
import { Communication } from './pages/communication/communication';
import { Register } from './pages/register/register';
import DealerInformation from './pages/dealer-information/dealer-information';
import ForgetPassword from './pages/forget-password/forget-password';
import ForgetPasswordSuccess from './pages/forget-password/forget-password-success';
import Dashboard from './pages/dashboard/dashboard';
import UserList from './pages/user-list/user-list';
import ContactsList from './pages/contacts/contacts-list';
import InspectionsInvitations from './pages/communication/inspections-invitations';
import LoyalityBonusReminder from './pages/communication/extended-warranty-reminder';
import ContactPools from './pages/contact-pools/contact-pools';
import DealerList from './pages/dealer-list/dealer-list';
import SetPassword from './pages/set-password/set-password';
import CompanyDetails from './pages/company-details/company-details';
import Support from './pages/support/support';
import UserEdit from './pages/user-edit/user-edit';
import VehicleCreate from './pages/vehicle-create/vehicle-create';
import ContactEdit from './pages/contact-edit/contact-edit';
import ContactCreate from './pages/contact-create/contact-create';
import CompanyEdit from './pages/company-edit/company-edit';
import CompanyCreate from './pages/company-create/company-create';
import { ToastContainer } from 'react-toastify';
import TwoFa from './pages/2fa/TwoFa';
import ContactsImport from './pages/contacts/contacts-import';
import { LoginSSO } from './pages/login-sso/login-sso';
import { environment } from '../environments/environment';

import PrivacyDataReport from './pages/contacts/privacy-data-report';
import TestDriveReport from './pages/communication/test-drive/report/test-drive-report';
import React, { useEffect, useState } from 'react';
import NewsletterVerification from './pages/communication/newsletter-verification';
import Report from './pages/report/report';
import CRRReport from './pages/report/crr-report';
import PlannedInspectionList from './pages/report/inspection-list/planned-inspection-list';
import Surveys from "./pages/communication/surveys";
import { BrochureDownloadRedirect } from "./pages/brochure-download-redirect/brochure-download-redirect";
import { isMotoDealer } from "./helpers/session-utils";
import { useSelector } from "react-redux";
import { selectAllUserManagement } from "./store/slices/user-management.slice";
import Help from './pages/help/help';

import WebRequestPage from "./pages/communication/web-request/web-request-page";
import { NewsletterHistory } from "./pages/communication/newsletter/NewsletterHistory";
import { NewsletterSearch } from "./pages/communication/newsletter/NewsletterSearch";
import { WebRequestType } from "./models/web-request";
import PDFDisplay from "./pages/help/pdf-display";
import CompletedInspectionList from "./pages/report/inspection-list/completed-inspection-list";
import SurveyReport from "./pages/report/survey/survey-report";
import TestDriveImport from "./pages/communication/test-drive/import/test-drive-import";
import NewsletterDefinition from "./pages/communication/newsletter/overview/newsletter-definition";
import ActivateNewsletterSubscription from "./pages/newsletter/activation-newsletter-subscription";
import UnsubscribeNewsletter from "./pages/newsletter/unsubscribe-newsletter";
import { ExternalRedirectWithQueryParams } from "./components/redirect-with-query-params/redirect-with-query-params";
import Sweepstakes from './pages/communication/sweepstakes';
import { RedirectionLayout } from "./layouts/redirection-layout";
import SweepstakeSubscriptions from "./pages/communication/web-request/sweepstake-subscriptions";

export function App() {
  const [testDriveRedirection, setTestDriveRedirection] = useState<string>("auto");
  const entities = useSelector(selectAllUserManagement);

  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      isMotoDealer(lastEntity.currentUser) && setTestDriveRedirection("moto");
    }
  }, [entities]);

  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/newsletter/subscription-confirmation/moto" element={<ExternalRedirectWithQueryParams to={environment.newsletter_redirections.moto.verify} />} />
          <Route path="/activate-moto-newsletter-subscription" element={<ExternalRedirectWithQueryParams to={environment.newsletter_redirections.moto.verify} />} />
          <Route path="/newsletter/unsubscription/moto" element={<ExternalRedirectWithQueryParams to={environment.newsletter_redirections.moto.unsubscribe} />} />
          <Route path="/unsubscribe-moto-newsletter" element={<ExternalRedirectWithQueryParams to={environment.newsletter_redirections.moto.unsubscribe} />} />
          <Route element={<RedirectionLayout />}>
            <Route path="/newsletter/subscription-confirmation/auto" element={<ActivateNewsletterSubscription />} />
            <Route path="/newsletter/unsubscription/auto" element={<UnsubscribeNewsletter />} />
          </Route>
          <Route element={<Public />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/2fa" element={<TwoFa />} />
            <Route path="/support" element={<Support />} />

            <Route
              path="/forget-password-success"
              element={<ForgetPasswordSuccess />}
            />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/login-sso" element={<LoginSSO />} />
            <Route path="/brochure-download-redirect" element={<BrochureDownloadRedirect />} />
          </Route>
          <Route element={<Protected />}>
            <Route path="/crm/index" />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contacts" element={<Contacts />}>
              <Route path="" element={<Navigate replace to="list/people" />} />
              <Route path="list" element={<Navigate replace to="people" />} />
              <Route path="list/:tabParam" element={<ContactsList />} />
              <Route path="import" element={<ContactsImport />} />
            </Route>
            <Route path="/contacts/create" element={<ContactCreate />} />
            <Route path="/contacts/edit/:id" element={<ContactEdit />} />
            <Route path="/contacts/view/:id" element={<ContactDetails />} />
            <Route path="/companies/create" element={<CompanyCreate />} />
            <Route path="/companies/edit/:id" element={<CompanyEdit />} />
            <Route path="/companies/view/:id" element={<CompanyDetails />} />
            <Route
              path="/contacts/dpe-report/:type"
              element={<PrivacyDataReport />}
            />
            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicles/add" element={<VehicleCreate />} />
            <Route path="/vehicles/edit/:id" element={<VehicleEdit />} />
            <Route path="/vehicles/view/:id" element={<VehicleDetails />} />
            <Route path="/communication" element={<Communication />}>
              <Route
                path=""
                element={
                  <Navigate replace to="inspections-invitations/toSend" />
                }
              />
              <Route
                path="inspections-invitations"
                element={<Navigate replace to="toSend" />}
              />
              <Route
                path="inspections-invitations/:tabParam"
                element={<InspectionsInvitations />}
              />
              <Route
                path="extended-warranty-reminder"
                element={<Navigate replace to="toSend" />}
              />
              <Route
                path="extended-warranty-reminder/:tabParam"
                element={<LoyalityBonusReminder />}
              />
              <Route
                  path="newsletter/overview"
                  element={<NewsletterDefinition />}
              />
              <Route
                  path="newsletter/export-history"
                  element={<NewsletterHistory />}
              />
              <Route
                path="newsletter/subscribers/:tenant"
                element={<NewsletterSearch />}
              />
              <Route
                path="newsletter/subscribers"
                element={<Navigate replace to="auto" />}
              />
              <Route
                path="newsletter/verification"
                element={<NewsletterVerification />}
              />
               <Route
                path="sweepstakes"
                element={<Sweepstakes />}
              />
              <Route
                path="test-drives-report"
                element={<Navigate replace to={testDriveRedirection} />}
              />
              <Route
                path="test-drives-import"
                element={<TestDriveImport />}
              />
              <Route
                path="web-request"
                element={<Navigate replace to="offers" />}
              />
              <Route
                path="web-request/offers"
                element={<WebRequestPage webRequestType={WebRequestType.OFFER}/>}
              />
              <Route
                path="web-request/business-offers"
                element={<WebRequestPage webRequestType={WebRequestType.BUSINESS_OFFER}/>}
              />
              <Route
                path="web-request/car-configuration-offers"
                element={<WebRequestPage webRequestType={WebRequestType.CAR_CONFIGURATION_OFFER}/>}
              />
              <Route
                path="web-request/sweepstakes-subscriptions"
                element={<SweepstakeSubscriptions/>}
              />
              <Route
                path="web-request/consulting-appointments"
                element={<WebRequestPage webRequestType={WebRequestType.CONSULTING_APPOINTMENT}/>}
              />
              <Route
                path="web-request/service-appointments"
                element={<WebRequestPage webRequestType={WebRequestType.SERVICE_APPOINTMENT}/>}
              />
              <Route
                path="test-drives-report/:tabParam"
                element={<TestDriveReport />}
              />
              <Route
                path="surveys/:typeParam"
                element={<Navigate replace to="toSend" />}
              />
              <Route
                path="surveys/:typeParam/:tabParam"
                element={<Surveys />}
              />
            </Route>
            <Route path="/admin" element={<UsersConfig />}>
              <Route path="" element={<Navigate replace to="users" />} />
              <Route path="users">
                <Route path="" element={<Navigate replace to="registered" />} />
                <Route path="registered" element={<UserList />} />
                <Route path="edit/:userId" element={<UserEdit />} />
                <Route path="logged" element={<div />} />
                <Route path="groups" element={<div />} />
                {/*  <Route path="policies" element={<div />} /> */}
              </Route>
              <Route path="dealers">
                <Route path="" element={<Navigate replace to="imported" />} />
                <Route path="imported" element={<DealerList />} />
              </Route>
              <Route path="contacts">
                <Route path="" element={<Navigate replace to="pools" />} />
                <Route path="pools" element={<ContactPools />} />
              </Route>
            </Route>
            <Route path="/reports" element={<Report />}>
              <Route path="" element={<Navigate replace to="crr" />} />
              <Route path="crr" element={<CRRReport />} />
              <Route path="survey/:typeParam" element={<SurveyReport />}/>
            </Route>
            <Route path="/inspection-planned" element={<PlannedInspectionList />} />
            <Route path="/inspection-completed" element={<CompletedInspectionList />} />
            <Route path="/help" element={<Help />}>
               <Route path="manual" element={<PDFDisplay filename={environment.assets.manual} />} />
              {/*<Route path="PDF_1" element={<PDFDisplay filename={environment.assets.PDF_1} />} />*/}
              {/*<Route path="PDF_2" element={<PDFDisplay filename={environment.assets.PDF_2} />} />*/}
            </Route>

            {/* <Route path='' element={<Navigate replace to="inspection" />} />
              <Route path="inspection" element={<InspectionReport />} />
            </Route> */}
            <Route path="/admin/users/register" element={<Register />} />

            <Route path="/admin/dealer/:id" element={<DealerInformation />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>

      <div className="hidden px-1 py-1"></div>
      {/* initialize tailwind classes*/}
    </div>
  );
}

export default App;
