import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Headline, Multiselect, MultiselectOptionsProps, Spinner, Tab, Table, Tabs, } from '@suzuki-frontend-framework-update/ui';
import defaultSort from '../utils/defaultSorter';
import { Row } from 'react-table';
import { useApiGetData } from '../../../../components/useApiGetData/useApiGetData';
import {
  APISubscriberFilter,
  GroupsResponse,
  IFilter,
  Newsletter,
  NewsletterActivationStatus,
  Subscriber,
  UnsubscribeInput,
} from '../../../../models/newsletter';

import { environment } from '../../../../../environments/environment';
import { sendApiRequest } from '../../../../components/send-api-request/send-api-request';
import { useTranslation } from 'react-i18next';
import { isAdmin } from "../../../../helpers/session-utils";
import { useSelector } from "react-redux";
import { selectAllUserManagement } from "../../../../store/slices/user-management.slice";
import { useNavigate, useParams } from "react-router-dom";
import { DatePickerRange, Filter, FilterItem, SelectInput, SelectOptionsProps } from "@sde/basic-ui-library";
import { VehicleModelType } from "../../../../models/vehicle-model";
import { Tenant } from "../../../../models/tenant";

const NewsletterSearch = () => {
  const navigate = useNavigate()
  const { tenant } = useParams();
  const entities = useSelector(selectAllUserManagement);
  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (!lastEntity?.currentUser || !isAdmin(lastEntity.currentUser)) {
      navigate('/communication')
    }
  }, [entities]);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selection, setSelection] = useState('csv');
  const [groupSelection, setGroupSelection] = useState('');
  const defaultGroupListValue = [
    {
      key: 0,
      value: '',
      name: 'Bitte wählen Sie eine Gruppe',
    } as SelectOptionsProps,
  ];
  const [groupList, setGroupList] = useState(defaultGroupListValue);
  const newslettersData = useApiGetData<Newsletter[]>(
    `${environment.restEndpoint}/contact/newsletter?tenant=${tenant?.toUpperCase() ?? Tenant.UNDEFINED.toUpperCase()}&activationStatus=${NewsletterActivationStatus.ACTIVE}`,
    'get',
    'data',
  );

  const [filterItems, setFilterItems] = useState<FilterItem[]>([]);

  function handleNewsletterSelection(_id: string, selected: string[], changedId: string) {
    const AllOptions = filterItems.map(item => item.id).filter(id => id !== 'all').sort() as string[];
    if (changedId === 'all') {
      const selectedIds = selected.includes('all') ? AllOptions : [];
      setSelectedNewsletterIds(selectedIds);
    } else {
      const updatedSelection = selected.filter(model => model !== 'all');
      setSelectedNewsletterIds(updatedSelection);
    }
  }

  const modelsData = useApiGetData<{ model: string }[]>(
    `${environment.restEndpoint}/contact/newsletter/model?scope=${VehicleModelType.UNDEFINED}`,
    'get',
    'data'
  );
  const [selectedNewsletterIds, setSelectedNewsletterIds] = useState<string[]>([]);

  /* prospect in crm column*/
  const [prospectFilter, setProspectFilter] = useState<IFilter>({
    active: false,
    models: [],
  });

  const handleProspectCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const currentProspectFilter = Object.assign({}, prospectFilter);
    currentProspectFilter.active = event.target.checked;
    setProspectFilter(currentProspectFilter);
  };
  const handleProspectFrom = (from: Date): void => {
    const currentProspectFilter = Object.assign({}, prospectFilter);
    currentProspectFilter.from = from;
    setProspectFilter(currentProspectFilter);
  };
  const handleProspectTo = (to: Date): void => {
    const currentProspectFilter = Object.assign({}, prospectFilter);
    currentProspectFilter.to = to;
    setProspectFilter(currentProspectFilter);
  };
  /* end prospect in crm column */
  /* customer column*/
  const [customerFilter, setCustomerFilter] = useState<IFilter>({
    active: false,
    models: [],
  });

  const handleCustomerCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const currentCustomerFilter = Object.assign({}, customerFilter);
    currentCustomerFilter.active = event.target.checked;
    setCustomerFilter(currentCustomerFilter);
  };
  const handleCustomerFrom = (from: Date): void => {
    const currentCustomerFilter = Object.assign({}, customerFilter);
    currentCustomerFilter.from = from;
    setCustomerFilter(currentCustomerFilter);
  };
  const handleCustomerTo = (to: Date): void => {
    const currentCustomerFilter = Object.assign({}, customerFilter);
    currentCustomerFilter.to = to;
    setCustomerFilter(currentCustomerFilter);
  };
  const handleActiveModelsSelect = (arr: MultiselectOptionsProps[]) => {
    const currentCustomerFilter = Object.assign({}, customerFilter);
    currentCustomerFilter.models = arr;
    setCustomerFilter(currentCustomerFilter);
  };
  /* end customer column */

  /* lost customer column */
  const [lostCustomerFilter, setLostCustomerFilter] = useState<IFilter>({
    active: false,
    models: [],
  });

  const handleLostCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentLostCustomerFilter = Object.assign({}, lostCustomerFilter);
    currentLostCustomerFilter.active = event.target.checked;
    setLostCustomerFilter(currentLostCustomerFilter);
  };

  const handleLastActiveModelsSelect = (arr: MultiselectOptionsProps[]) => {
    const currentLostCustomerFilter = Object.assign({}, lostCustomerFilter);
    currentLostCustomerFilter.models = arr;
    setLostCustomerFilter(currentLostCustomerFilter);
  };
  /* end lost customer column */

  useEffect(() => {
    if (selection === 'cleverreach' && groupList.length === 0) {
      sendApiRequest(
        `${environment.restEndpoint}/cleverreach/groups?isMoto=${tenant === Tenant.MOTO}`,
        'get',
      )
        .then((res) => res.json())
        .then((res) => {
          const groups = new GroupsResponse(res.groups);
          const selectOptionsGroups = groups.toSelectOptions();
          setGroupList(selectOptionsGroups);
          setGroupSelection(selectOptionsGroups[0].value as string);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (selection !== 'cleverreach' && groupList.length !== 0) {
      setGroupList([]);
    }
  }, [selection, groupList]);

  useEffect(() => {
    if (newslettersData.data && newslettersData.data.length > 0) {
      const newFilterItems = newslettersData.data.map((n) => ({
        id: n.objectId,
        name: n.name,
      } as FilterItem));
      setFilterItems(newFilterItems);
    }
  }, [newslettersData.data]);

  const handleUnsubscribe = (row: Subscriber) => {
    if (
      window.confirm(
        `Sind Sie sicher, dass sie die Email ${row.email} vom Newsletter abmelden möchten?`
      )
    ) {
      sendApiRequest(
        `${environment.restEndpoint}/contact/newsletter/unsubscribe`,
        'post',
          {
            email: row.email,
            newsletterID: row.newsletter.objectId,
            byAdmin: true
          } as UnsubscribeInput
      ).then((res) => {
        showData(undefined);
      });
    }
  };

  const handleSelect = (value: string) => {
    setSelection(value);
    setShowTable(false);
  };

  const handleGroupSelect = (value: string) => {
    setGroupSelection(value);
    setShowTable(false);
  };

  const [subscriber, setSubscriber] = useState<Subscriber[]>([]);

  /* handle form */
  const showData = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.preventDefault();
    }
    setSubscriber([]);
    setLoading(true);

    const filter = new APISubscriberFilter({
      newsletterIds: selectedNewsletterIds ?? [],
      prospectFilter,
      customerFilter,
      lostCustomerFilter,
    });
    try {
      const response = await sendApiRequest(
        `${environment.restEndpoint}/contact/newsletter/subscriber/getSubscribers`,
        'POST',
        { input: filter }
      );
      const json = await response.json();
      setSubscriber(json['data']);
      setLoading(false);
      setShowTable(true);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const exportData = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const filter = new APISubscriberFilter({
      newsletterIds: selectedNewsletterIds ?? [],
      prospectFilter,
      customerFilter,
      lostCustomerFilter,
    });
    filter.exportType = selection ? selection.toLocaleUpperCase() : 'CSV';
    filter.cleverreachGroupId = groupSelection ? groupSelection : undefined;
    sendApiRequest(
      `${environment.restEndpoint}/contact/newsletter/exports`,
      'post',
      {
        input: filter,
      }
    )
      .then((resp) => {
        navigate('/communication/newsletter/export-history');
        return resp.json();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const customerStateMapper = (customerstate: string): JSX.Element => {
    switch (customerstate) {
      case 'POTENTIAL_CUSTOMER':
      case 'PROSPECT_CUSTOMER':
        return <>I</>;
      case 'LOST_CUSTOMER':
        return <>LC</>;
      case 'CUSTOMER':
        return <>C</>;
      default:
        return <>Unknown</>;
    }
  };

  const columns = [
    {
      Header: 'Status',
      accessor: 'customerState',
      Cell: ({ cell }: any) => (
        <React.Fragment>{customerStateMapper(cell.value)}</React.Fragment>
      ),
    },
    {
      Header: 'Kundennummer',
      accessor: 'customerNumber',
    },
    {
      Header: 'E-Mail',
      accessor: 'email',
      filter: 'fuzzyText',
      sortType: (rowA: Row<Subscriber>, rowB: Row<Subscriber>) =>
        defaultSort(rowA.values['email'], rowB.values['email']),
    },
    {
      Header: 'Name',
      accessor: 'fullname',
      Cell: ({ row }: any) => (
        <span>
          {`${row.original.salutation ? row.original.salutation : '...'} ${
            row.original.firstname ? row.original.firstname : ''
          } ${row.original.name ? row.original.name : ''}`}
        </span>
      ),
    },
    {
      Header: 'Letzter Kontakt',
      accessor: 'lastContact',
      Cell: ({ row }: any) => (
        <span>
          <p>
            {row.original.lastContact
              ? new Date(row.original.lastContact).toLocaleDateString()
              : '-'}
          </p>
        </span>
      ),
    },
    {
      Header: 'Aktionen',
      accessor: 'removal',
      Cell: ({ row }: any) => (
        <span>
          {row.original.email ? (
            <p
              className="text-darkblue hover:text-darkblueactive cursor-pointer"
              onClick={() => handleUnsubscribe(row.original)}
            >{`Abmelden`}</p>
          ) : null}
        </span>
      ),
    },
  ];

  const handleTenantTabChange = (tabIndex: number) => {
    const motoTab = tabIndex === 1;
    navigate(
        `/communication/newsletter/subscribers/${motoTab ? Tenant.MOTO : Tenant.AUTO}`
    );
  };

  const getTenantTabIndex = () => {
    switch (tenant) {
      case Tenant.AUTO:
        return 0;
      case Tenant.MOTO:
        return 1;
      default:
        return 0;
    }
  };

  const resetStates = () => {
    setSubscriber([]);
    setSelectedNewsletterIds([]);
    setFilterItems([]);
    setSelection('csv');
    setGroupSelection('');
    setGroupList(defaultGroupListValue);
  }

  useEffect(() => {
    resetStates();
  }, [tenant]);

  return (
    <div className="w-full h-full">
      <div className="p-8">
        <Headline title={t('newsletter-search.headline')}/>
      </div>
      <Tabs defaultActiveTabIndex={getTenantTabIndex()} onTabChange={handleTenantTabChange}>
        <Tab title={t('newsletter-search.tenant.auto')} tabIndex={0}/>
        <Tab title={t('newsletter-search.tenant.moto')} tabIndex={1}/>
      </Tabs>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="grid grid-cols-1 gap-y-8 p-8 -mt-12">
          <div>
            <label
              className="text-base text-black font-suzuki-bold block mr-4 mb-4 whitespace-nowrap"
              htmlFor={'newsletter'}
            >
              {'NEWSLETTER'}
              {'*'}
            </label>
            <Filter
              id={'newsletter'}
              name={'Newsletter'}
              items={filterItems}
              selected={selectedNewsletterIds}
              onChange={handleNewsletterSelection}
              width="20rem"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 lg:items-start">
            {/* Prospect column */}
            <div>
              <div className="">
                <Checkbox
                  label="Suzuki-Interessenten"
                  checked={prospectFilter.active}
                  onChange={handleProspectCheckbox}
                  rightLabel={true}
                />
              </div>
              <div className="mt-10 ">
                <DatePickerRange
                  label={t('newsletter-search.date_for_newsletter_registration')}
                  from={prospectFilter.from}
                  to={prospectFilter.to}
                  changeFrom={handleProspectFrom}
                  changeTo={handleProspectTo}
                  customWidth="w-28"
                />
              </div>
            </div>
            {/* End prospect column */}
            {/* Suzuki-Kunden column */}
            <div>
              <div>
                <Checkbox
                  label="Suzuki-Kunden"
                  checked={customerFilter.active}
                  onChange={handleCustomerCheckbox}
                  rightLabel={true}
                />
              </div>
              <div className="mt-10 mr-8 w-full">
                <DatePickerRange
                  label="Endkunden-Registrierung"
                  from={customerFilter.from}
                  to={customerFilter.to}
                  changeFrom={handleCustomerFrom}
                  changeTo={handleCustomerTo}
                  customWidth="w-28"
                />
              </div>
              <div className="mt-10">
                <Multiselect
                  label={{ name: 'Aktuell aktives modell' }}
                  displayToken="name"
                  values={customerFilter.models}
                  options={
                    modelsData.data
                      ? modelsData.data?.filter(model => model.model).map((m, i) => {
                          return {
                            key: i,
                            name: m.model,
                            value: m.model,
                          } as MultiselectOptionsProps;
                        })
                      : []
                  }
                  onSelect={handleActiveModelsSelect}
                  onRemove={handleActiveModelsSelect}
                  width={12}
                  unit="rem"
                />
              </div>
            </div>
            {/* End suzuki-kunden column */}
            {/* Start Lost customer column */}
            <div>
              <div>
                <Checkbox
                  label="Suzuki-Lost customer"
                  checked={lostCustomerFilter.active}
                  onChange={handleLostCheckbox}
                  rightLabel={true}
                />
              </div>
              <div className="mt-10">
                <Multiselect
                  label={{ name: 'Letztes aktives modell' }}
                  displayToken="name"
                  values={lostCustomerFilter.models}
                  options={
                    modelsData.data
                      ? modelsData.data?.filter(model => model.model).map((m, i) => {
                          return {
                            key: i,
                            name: m.model,
                            value: m.model,
                          } as MultiselectOptionsProps;
                        })
                      : []
                  }
                  onSelect={handleLastActiveModelsSelect}
                  onRemove={handleLastActiveModelsSelect}
                  width={12}
                  unit="rem"
                />
              </div>
            </div>
          </div>
          {/* End Lost customer column */}

          {/* Start Kontakte anzeigen column */}

          <div className="flex">
            <div>
              <Button onClick={showData}>Kontakte anzeigen</Button>
            </div>
          </div>
        </div>
        {/* End Kontakte anzeigen column */}
      </form>
      <div className="my-8">
        <hr />
      </div>
      {loading && (
        <div className="flex items-align justify-center m-4">
          <div className="flex flex-col">
            <div className="flex items-align justify-center">
              <p className="text-lg text-darkblue font-bold">
                Suche nach Kontakten....
              </p>
            </div>
            <div>
              <Spinner />
            </div>
          </div>
        </div>
      )}
      {!loading && subscriber.length === 0 && (
        <div className="flex justify-center">
          <p className="text-dark">Keine Einträge gefunden</p>
        </div>
      )}

      {subscriber && subscriber.length > 0 && (
        <div className="overflow-x-scroll max-w-full">
          {/* Start Export nach column */}
          <div className="flex items-end pb-4">
            <SelectInput
              label={{ name: 'Export nach', position: 'top' }}
              value={selection}
              options={[
                {
                  key: '0',
                  value: 'cleverreach',
                  name: 'Cleverreach',
                },
                { key: '1', value: 'csv', name: 'CSV' },
              ]}
              onSelect={handleSelect}
            />
            {selection === 'cleverreach' && (
              <div className="ml-4">
                <SelectInput
                  label={{ name: 'Cleverreach Gruppe', position: 'top' }}
                  value={groupSelection}
                  options={groupList}
                  onSelect={handleGroupSelect}
                />
              </div>
            )}
            <div className="px-4">
              <Button className={'bg-dark'} onClick={exportData}>
                Kontakte exportieren
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            data={subscriber}
            rowCount={subscriber.length}
            countLabel={
              subscriber.length === 1
                ? t('common.list.count.entry')
                : t('common.list.count.entries')
            }
            pagination
            initialPage={0}
            basic={true}
          />
        </div>
      )}
    </div>
  );
};

export default NewsletterSearch;
