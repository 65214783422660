import {
  GetNewsletterTypeResponse,
  IdRequest,
  Newsletter, NewsletterCreateResponse,
  NewsletterInput,
  NewsletterUpdateResponse,
  UnsubscribeInput,
  UnsubscribeResponse,
  UpdateSuccessful
} from '../../models/newsletter';
import { Tenant } from "../../models/tenant";
import { sendApiRequest, sendUnauthorizedApiRequest } from "../../components/send-api-request/send-api-request";
import { environment } from "../../../environments/environment";

const baseUrl = '/contact/newsletter';

export async function fetchNewslettersData(tenant: string, activationStatus: string): Promise<Newsletter[]> {
  const { data } = await sendApiRequest(
    `${environment.restEndpoint}${baseUrl}?tenant=${tenant.toUpperCase() ?? Tenant.UNDEFINED.toUpperCase()}&activationStatus=${activationStatus}`,
    'get')
    .then((res) => res.json());
  return data;
}


export async function verifySubscriber(body: NewsletterInput): Promise<UpdateSuccessful> {
  return await sendUnauthorizedApiRequest(
    `${environment.restEndpoint}${baseUrl}/subscriber/verify`,
    'post',
    body)
    .then((res) => res.json());
}

export async function unsubscribe(body: UnsubscribeInput): Promise<UnsubscribeResponse> {
  return await sendUnauthorizedApiRequest(
    `${environment.restEndpoint}${baseUrl}/unsubscribe`,
    'post',
    body)
    .then((res) => res.json());
}

export async function getNewsletterType(newsletterCode: string): Promise<GetNewsletterTypeResponse> {
  return await sendUnauthorizedApiRequest(
    `${environment.restEndpoint}${baseUrl}/type/${newsletterCode}`,
    'get').then((res) => res.json());
}

export async function createNewsletter(body: Newsletter): Promise<NewsletterCreateResponse> {
  return await sendApiRequest(
    `${environment.restEndpoint}${baseUrl}`,
    'post',
    body)
    .then((res) => res.json());
}

export async function updateNewsletter(body: Newsletter): Promise<NewsletterUpdateResponse> {
  return await sendApiRequest(
    `${environment.restEndpoint}${baseUrl}/${body.objectId}`,
    'put',
    {
      newsletter: body
    })
    .then((res) => res.json())

}

export async function deleteNewsletter(id: string): Promise<NewsletterUpdateResponse> {
  return await sendApiRequest(
    `${environment.restEndpoint}${baseUrl}/${id}`,
    'delete')
    .then((res) => res.json())
}

export async function deactivateNewsletter(body: IdRequest): Promise<NewsletterUpdateResponse> {
  return sendApiRequest(
    `${environment.restEndpoint}${baseUrl}/deactivate`,
    'post',
    body)
    .then((res) => res.json());
}
