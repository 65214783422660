import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BasicTable, Checkbox, DatePickerRange } from "@sde/basic-ui-library";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useApiGetData } from "../../../components/useApiGetData/useApiGetData";
import { environment } from "../../../../environments/environment";
import { DataFetchProps } from "../../../models/others";
import { useDispatch, useSelector } from "react-redux";
import { webRequestFilterActions, webRequestFilterSelector } from "../../../store/slices/web-request-filter.slice";
import { AppDispatch } from "../../../store/setup";
import { sendApiRequest } from "../../../components/send-api-request/send-api-request";
import { isAdmin, isAreaAdmin } from "../../../helpers/session-utils";
import { Session } from "@ory/client";
import { selectAllUserManagement } from "../../../store/slices/user-management.slice";
import { Button, Headline, Icon } from "@suzuki-frontend-framework-update/ui";
import {
  LeadType,
  WebRequest,
  WebRequestEntityContactView,
  WebRequestStatusMap,
  WebRequestType
} from "../../../models/web-request";
import ReportFilters from "../../../components/report-filter/report-filter";
import { prepareInitialEndDate, prepareInitialStartDate } from "../../../helpers/date-helper";
import { dashboardFilterSelector } from "../../../store/slices/dashboard.slice";
import moment from "moment";
import { toast } from "react-toastify";

export interface WebRequestProps {
  webRequestType: WebRequestType;
}

const WebRequestPage = ({ webRequestType }: WebRequestProps) => {
  const {t, i18n} = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const pageSize = 100;
  const entities = useSelector(selectAllUserManagement);
  const location = useLocation();
  const fromDashboard = new URLSearchParams(location.search).get('dashboard');
  const dashboardFilterState = useSelector(dashboardFilterSelector);
  const now = new Date();
  const [dateFrom, setDateFrom] = useState<Date | undefined>(prepareInitialStartDate(fromDashboard === 'true', dashboardFilterState, new Date(now.getFullYear(), now.getMonth() - 2, now.getDate())));
  const [dateTo, setDateTo] = useState<Date | undefined>(prepareInitialEndDate(fromDashboard === 'true', dashboardFilterState, now));
  const [webRequestStatusMap, setWebRequestStatusMap] = useState<WebRequestStatusMap>({});
  const [session, setSession] = useState({} as Session);
  useEffect(() => {
    const lastEntity = entities[entities.length - 1];
    if (lastEntity && lastEntity.currentUser) {
      setSession(lastEntity.currentUser);
    }
  }, [entities]);

  const obtainMainPartOfTheUrl = (): string => {
    let result = '';
    switch(webRequestType) {
      case WebRequestType.OFFER:
      case WebRequestType.BUSINESS_OFFER:{
        result = 'offers';
        break;
      }
      case WebRequestType.CAR_CONFIGURATION_OFFER: {
        result = 'car-configuration-offers';
        break;
      }
      case WebRequestType.CONSULTING_APPOINTMENT: {
        result = 'appointments/consulting';
        break;
      }
      case WebRequestType.SERVICE_APPOINTMENT: {
        result = 'appointments/vehicle-service';
        break;
      }
    }
    return result;
  }
  const [urlMainPart, setUrlMainPart] = useState<string>(obtainMainPartOfTheUrl());

  useEffect(() => {
    setUrlMainPart(obtainMainPartOfTheUrl());
  }, [webRequestType]);

  const webRequestFilterState = useSelector(webRequestFilterSelector);
  const [webRequest, setWebRequest] = useState<WebRequest>({
    pageSize: pageSize,
    pageIndex: webRequestFilterState.data.pageIndex,
    filter: {
      dateFrom: dateFrom,
      dateTo: dateTo,
      search: webRequestFilterState.data.filter.search,
      dealers: webRequestFilterState.data.filter.dealers
    }
  });

  const webRequestEntities = useApiGetData<WebRequestEntityContactView[]>(
    `${environment.restEndpoint}/action/${urlMainPart}/list`,
    'post',
    'entities',
    webRequest
  );

  const webRequestEntitiesCount = useApiGetData<number>(
    `${environment.restEndpoint}/action/${urlMainPart}/count/get`,
    'post',
    'count',
    webRequest
  );

  useEffect(() => {
    if (webRequestEntities.data) {
      const statusMap = webRequestEntities.data.reduce((acc, entity) => {
        acc[entity.id] = entity.completed ?? false;
        return acc;
      }, {} as WebRequestStatusMap);
      setWebRequestStatusMap(statusMap);
    }
  }, [webRequestEntities.data]);

  const handleStatusChange = (webRequestEntity: WebRequestEntityContactView) => {
    if (!webRequestEntity.id) return;

    const newStatus = !webRequestStatusMap[webRequestEntity.id];
    setWebRequestStatusMap({ ...webRequestStatusMap, [webRequestEntity.id]: newStatus })
    sendApiRequest(`${environment.restEndpoint}/action/${urlMainPart}/complete`, 'post', {
      id: webRequestEntity.id
    }).catch(error => {
      handleUnsuccessfulStatusChange(webRequestEntity.id, newStatus, error.toString());
    });
  }

  const handleUnsuccessfulStatusChange = (webRequestEntityId: string, newStatus: boolean, errorMessage: string) => {
    console.error(errorMessage);
    setWebRequestStatusMap({ ...webRequestStatusMap, [webRequestEntityId]: !newStatus });
    toast.error(t('communication.web-request.completion-failed').toString(), {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'web-request-status-change-failed',
    });
  }

  const columnHelper = createColumnHelper<WebRequestEntityContactView>();
  const columns: ColumnDef<WebRequestEntityContactView>[] = [
    isAdmin(session) || isAreaAdmin(session) ? columnHelper.accessor((row) => row.dealercode, {
      id: 'dealercode',
      enableSorting: false,
      header: () => <span>{t('communication.web-request.dealercode')}</span>,
      cell: (info) => {
        return <span>
                {info.getValue()}
          </span>
      },
    }) : null,
    webRequestType === WebRequestType.BUSINESS_OFFER &&
    columnHelper.accessor((row) => row, {
      id: i18n.language === 'en' ? 'companyBusinessTypeSortEnglish' : 'companyBusinessTypeSortGerman',
      enableSorting: true,
      header: () => <span>{t('communication.web-request.business_type_header')}</span>,
      cell: (info) => {
        const webRequestEntity = info.getValue();
        return <span>
            {t(`communication.web-request.business_type.${webRequestEntity.companyBusinessType}`)}
          </span>;
      },
    }),
    columnHelper.accessor((row) => row, {
      id: 'contactNumber',
      enableSorting: false,
      header: () => <span>{t('communication.web-request.contact-number')}</span>,
      cell: (info) => {
        const webRequestEntity = info.getValue()
        return <Link className={'underline'} to={`/contacts/view/${webRequestEntity.contactId}`} >
          {webRequestEntity.contactNumber}
        </Link>
      },
    }),
    columnHelper.accessor((row) => row.contactEmail, {
      id: 'email',
      enableSorting: false,
      header: () => <span>{t('communication.web-request.contact-email')}</span>,
      cell: (info) => {
        return <span>
          {info.getValue()}
        </span>
      },
    }),

    webRequestType === WebRequestType.CAR_CONFIGURATION_OFFER &&
    columnHelper.accessor((row) => row.model, {
      id: 'model',
      enableSorting: true,
      header: () => <span>{t('communication.web-request.models')}</span>,
      cell: (info) => {
        return <span>
          {info.getValue()}
        </span>
      },
    }),

    [WebRequestType.BUSINESS_OFFER, WebRequestType.OFFER].includes(webRequestType) &&
    columnHelper.accessor((row) => row.models, {
      id: 'models',
      enableSorting: true,
      header: () => <span>{t('communication.web-request.models')}</span>,
      cell: (info) => {
        return <span>
          {info.getValue()?.join(', ')}
        </span>
      },
    }),

    columnHelper.accessor((row) => row, {
      id: 'contactName',
      enableSorting: false,
      header: () => <span>{t('communication.web-request.contact-name')}</span>,
      cell: (info) => {
        const webRequestEntity = info.getValue()
        return <Link className={'underline'} to={`/contacts/view/${webRequestEntity.contactId}`} >
          {webRequestEntity.contactSalutation} {webRequestEntity.contactFirstName} {webRequestEntity.contactLastName}
        </Link>
      },
    }),
    webRequestType === WebRequestType.BUSINESS_OFFER &&
    columnHelper.accessor((row) => row, {
      id: 'companyName',
      enableSorting: false,
      header: () => <span>{t('communication.web-request.company_name')}</span>,
      cell: (info) => {
        const webRequestEntity = info.getValue();
        return <Link className={'underline'} to={`/companies/view/${webRequestEntity.companyId}`} >
          {webRequestEntity.companyName}
        </Link>;
      },
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      enableSorting: true,
      header: () => <span>{t('communication.web-request.created-at')}</span>,
      cell: (info) => {
        const createdAt = info.getValue()
        return <span>
                {createdAt ? new Date(createdAt).toLocaleDateString() : '-'}
          </span>
      },
    }),
    columnHelper.accessor((row) => row, {
      id: 'completed',
      enableSorting: true,
      header: () => <span>{t('communication.web-request.completed')}</span>,
      cell: (info) => {
        const webRequestEntity = info.getValue();
        const isChecked = webRequestStatusMap[webRequestEntity.id] || false;
        return <Checkbox
          id={webRequestEntity.id}
          checked={isChecked}
          onChange={(e) => handleStatusChange(webRequestEntity)}
        />
      },
    }),
  ].filter(Boolean) as ColumnDef<WebRequestEntityContactView>[]

  const fetchWebRequestEntitiesData = useCallback(
    async ({pageSize, pageIndex, pageSortBy}: DataFetchProps) => {
      dispatch(webRequestFilterActions.updatePageIndex(pageIndex));
      let sortByDir = 1;
      let sortByField = '';
      if (pageSortBy && pageSortBy.length > 0) {
        const sortParams = pageSortBy[0];
        sortByDir = sortParams.desc ? -1 : 1;
        sortByField = sortParams.id;
      }
      setWebRequest({
        pageSize: pageSize,
        pageIndex: pageIndex,
        sortField: sortByField,
        sortDir: sortByDir,
        filter: {
          ...webRequestFilterState.data.filter,
          dateTo: dateTo,
          dateFrom: dateFrom,
          leadType: webRequestType === WebRequestType.BUSINESS_OFFER ? LeadType.COMPANY : LeadType.PERSON,
        }
      });
    },
    [webRequestFilterState.data.filter, webRequestType]
  );

  const handlePreviousRow = useCallback(
    async ({itemsRef}: any) => {
      if (webRequestEntities.data && webRequestFilterState.currentRow) {
        itemsRef.current
          .get(webRequestFilterState.currentRow)
          ?.scrollIntoView({behavior: 'smooth', block: 'center'});
        dispatch(webRequestFilterActions.updateCurrentRow(undefined));
      }
    },
    [webRequestEntities.data, webRequestEntities.loading]
  );

  const handleWebRequestFilterSearch = (value: string) => {
    dispatch(
        webRequestFilterActions.updateFilter({
          ...webRequestFilterState.data.filter,
          search: value,
        })
    );
  };

  const webRequestsFilter = {
    id: 'webRequestsFilters',
    key: 'webRequestsFilters',
    placeholder: t('communication.web-request.search-placeholder'),
    onSearchUpdate: handleWebRequestFilterSearch,
    searchDelay: 500,
    initialSearchValue: webRequestFilterState.data.filter.search,
  };

  const obtainHeaderText = () => {
    let result = t('communication.web-request.title');
    switch(webRequestType) {
      case WebRequestType.OFFER: {
        result = t('communication.web-request.subpages.offers');
        break;
      }
      case WebRequestType.BUSINESS_OFFER: {
        result = t('communication.web-request.subpages.business_offers');
        break;
      }
      case WebRequestType.CAR_CONFIGURATION_OFFER: {
        result = t('communication.web-request.subpages.car-configuration-offers');
        break;
      }
      case WebRequestType.CONSULTING_APPOINTMENT: {
        result = t('communication.web-request.subpages.consulting-appointments');
        break;
      }
      case WebRequestType.SERVICE_APPOINTMENT: {
        result = t('communication.web-request.subpages.service-appointments');
        break;
      }
    }
    return result;
  }

  const handleFilterDateFrom = (from: Date) => {
    setDateFrom(from)
    dispatch(webRequestFilterActions.updateFilter({
      ...webRequestFilterState.data.filter,
      dateFrom: moment(from).format('DD.MM.YYYY')
    }));
  };

  const handleFilterDateTo = (to: Date) => {
    setDateTo(to)
    dispatch(webRequestFilterActions.updateFilter({
      ...webRequestFilterState.data.filter,
      dateTo: moment(to).format('DD.MM.YYYY')
    }));
  };

  useEffect(() => {
    dispatch(
        webRequestFilterActions.updateFilter({
          ...webRequestFilterState.data.filter,
          dealers: dashboardFilterState.dealerFilter.dealers,
        })
    );
  }, [dashboardFilterState.dealerFilter.dealers]);

  return (
    <div className="p-8">
      {fromDashboard === 'true' && (
        <div className="h-fit w-fit">
          <Button
            py="4"
            className="w-full mb-8"
            onClick={() => {navigate(-1);}}
          >
            <Icon iconName={'arrow_back'} className="mr-1"></Icon>
            {t('communication.test-drive.report.back')}
          </Button>
        </div>
      )}
      <Headline
        title={obtainHeaderText()}
      />
      <div className="mb-2 -mt-1">
        <ReportFilters/>
      </div>
      <div className="pb-4 mt-7">
        <DatePickerRange
          id="createAt"
          labelFrom={t(
            `communication.test-drive.report.filter-option.from`
          )}
          labelTo={t(`communication.test-drive.report.filter-option.to`)}
          from={dateFrom}
          to={dateTo}
          changeFrom={handleFilterDateFrom}
          changeTo={handleFilterDateTo}
        />
      </div>
      <BasicTable<WebRequestEntityContactView>
        key={`web-request-table-${webRequestType}`}
        columns={columns}
        filter={webRequestsFilter}
        data={webRequestEntities.data ?? []}
        initPageSize={pageSize}
        loading={webRequestEntities.loading}
        rowCount={webRequestEntitiesCount.data ?? 0}
        countLabel={
          webRequestEntitiesCount.data === 1
            ? t('common.list.count.entry')
            : t('common.list.count.entries')
        }
        fetchData={fetchWebRequestEntitiesData}
        initialPage={webRequestFilterState.data.pageIndex}
        basic
        handleScrollToRow={handlePreviousRow}
        resetOnChange={webRequestFilterState.data.filter}
      />
    </div>
  );
}

export default WebRequestPage
