export interface WebRequestEntityContactView {
  id: string;
  contactId?: string;
  dealercode?: string;
  createdAt?: Date;
  models?: string[];
  model?: string;
  origin?: string;
  completed?: boolean;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactNumber?: string;
  contactEmail?: string;
  companyId?: string;
  companyBusinessType?: BusinessType;
  companyName?: string;
}

export interface WebRequest {
    filter?: WebRequestFilter;
    pageSize?: number;
    pageIndex?: number;
    sortField?: string;
    sortDir?: number;
}

export interface WebRequestFilter {
    dateFrom?: Date;
    dateTo?: Date;
    search?: string;
    contactId?: string;
    dealers?: string[];
    models?: ModelFilter[];
    notCompleted?: boolean;
    leadType?: LeadType;
}

export interface ModelFilter {
    model?: string;
    from?: Date;
}

export enum WebRequestType {
    OFFER = 'OFFER',
    CAR_CONFIGURATION_OFFER = 'CAR_CONFIGURATION_OFFER',
    CONSULTING_APPOINTMENT = 'CONSULTING_APPOINTMENT',
    SERVICE_APPOINTMENT = 'SERVICE_APPOINTMENT',
    BUSINESS_OFFER = 'BUSINESS_OFFER',
}

export interface WebRequestStatusMap {
  [id: string]: boolean;
}

export enum BusinessType {
  NOT_SET = 'NOT_SET',
  CARE_SERVICES = 'CARE_SERVICES',
  DELIVERY_SERVICES = 'DELIVERY_SERVICES',
  SMALL_BUSINESS = 'SMALL_BUSINESS',
  DRIVING_SCHOOL = 'DRIVING_SCHOOL',
  CAB_COMPANY = 'CAB_COMPANY',
}

export enum LeadType {
  NOT_SET = 'NOT_SET',
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}
